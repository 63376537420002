import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './PayMethods.module.scss'

function PayMethods({
  result,
  payment_method_id,
  setPayment_method_id,
  payMethodId,
  setRedirect,
  setNoIframe,
  cloud,
  setCloud,
  promoRes,
  setCurrency,
  setPmidForPaytabs
}) {
  const [rf, setRf] = useState(true)
  const [eng, setEng] = useState(false)
  const [tinkoff_sbp, setTinkoff_sbp] = useState(0)
  const [tinkoff, setTinkoff] = useState(0)
  const [prodamus, setProdamus] = useState(0)
  const [paytabs, setPaytabs] = useState(0)
  const [terpay, setTerpay] = useState(0)
  const [moneta, setMoneta] = useState(0)
  const [disBtns, setDisBtns] = useState(false)

  const recStatus = result?.recurrent_status ?? false
  const companyId = result?.frame_companies?.find((el) => el)?.company_id
  const payment_method = result?.frame_companies?.find((el) => el)?.company_info
    .payment_methods

  const location = useLocation()
  const url = window.location.href
  const urlOrgin = new URL(url).origin
  const gate = new URLSearchParams(location.search).get('gate')

  useEffect(() => {
    //по дефолту выбирается карта
    if ((cloud || tinkoff) && gate !== '1' && !payMethodId) {
      setPayment_method_id(cloud || tinkoff)
    }
  }, [cloud, gate, payMethodId, tinkoff])

  const getRandomPayId = (arr) => {
    //когда несколько одинаковых типов платежных методов для рандомного распределения
    if (arr.length) {
      const randomIndex = Math.floor(Math.random() * arr.length)
      const randomElement = arr[randomIndex]
      return randomElement.id
    } else {
      return 0
    }
  }

  useEffect(() => {
    //распределение платежных методов

    // массивы нужны на случай нескольких одинаковых типов платежных методов
    const cardArr = []
    const prodamusArr = []
    const tinkoff_sbpArr = []
    const tinkoffArr = []
    const paytabsArr = []
    const terpayArr = []
    const monetaArr = []

    if (!payMethodId) {
      payment_method?.map((el) => {
        if (el.merchant_type === 'cloudpayments') {
          if (!el.pm_disabled) {
            cardArr.push(el)
            setCloud(getRandomPayId(cardArr))
            recStatus === true && setPayment_method_id(getRandomPayId(cardArr))
            gate === '1' &&
              recStatus === true &&
              setPayment_method_id(getRandomPayId(cardArr))
          }
        } else if (el.merchant_type === 'tinkoff') {
          if (!el.pm_disabled) {
            tinkoffArr.push(el)
            setTinkoff(getRandomPayId(tinkoffArr))
          }
        } else if (el.merchant_type === 'prodamus') {
          if (!el.pm_disabled) {
            prodamusArr.push(el)
            setProdamus(getRandomPayId(prodamusArr))
            gate === '1' &&
              recStatus === false &&
              setPayment_method_id(getRandomPayId(prodamusArr))
          }
        } else if (el.merchant_type === 'tinkoff_sbp') {
          if (!el.pm_disabled) {
            tinkoff_sbpArr.push(el)
            setTinkoff_sbp(getRandomPayId(tinkoff_sbpArr))
          }
        } else if (el.merchant_type === 'paytabs') {
          if (!el.pm_disabled) {
            paytabsArr.push(el)
            setPaytabs(getRandomPayId(paytabsArr))
            setPmidForPaytabs(el.id)
            gate === '1' &&
              recStatus === false &&
              setPayment_method_id(getRandomPayId(paytabsArr))
          }
        } else if (el.merchant_type === 'terpay') {
          if (!el.pm_disabled) {
            terpayArr.push(el)
            setTerpay(getRandomPayId(terpayArr))
          }
        } else if (el.merchant_type === 'moneta') {
          if (!el.pm_disabled) {
            monetaArr.push(el)
            setMoneta(getRandomPayId(monetaArr))
          }
        }
      })
    }
  }, [payment_method, recStatus, gate, payMethodId])

  useEffect(() => {
    //валюта платежного метода
    if (payment_method?.length && payment_method_id) {
      const filtered = payment_method?.filter(
        (item) => item.id === payment_method_id
      )
      const obj = filtered[0]
      setCurrency(obj?.currency)
    }
  }, [payment_method, payment_method_id])

  useEffect(() => {
    //если у компании один метод оплаты
    if (
      !cloud &&
      !prodamus &&
      tinkoff_sbp &&
      !paytabs &&
      !terpay &&
      !moneta &&
      !tinkoff
    ) {
      setPayment_method_id(tinkoff_sbp)
      setDisBtns(true)
    } else if (
      !cloud &&
      prodamus &&
      !tinkoff_sbp &&
      !paytabs &&
      !terpay &&
      !moneta &&
      !tinkoff
    ) {
      setPayment_method_id(prodamus)
      setDisBtns(true)
    } else if (
      cloud &&
      !prodamus &&
      !tinkoff_sbp &&
      !paytabs &&
      !terpay &&
      !moneta &&
      !tinkoff
    ) {
      setPayment_method_id(cloud)
      setDisBtns(true)
    } else if (
      !cloud &&
      !prodamus &&
      !tinkoff_sbp &&
      paytabs &&
      !terpay &&
      !moneta &&
      !tinkoff
    ) {
      setPayment_method_id(paytabs)
      setDisBtns(true)
    } else if (
      !cloud &&
      !prodamus &&
      !tinkoff_sbp &&
      !paytabs &&
      terpay &&
      !moneta &&
      !tinkoff
    ) {
      setPayment_method_id(terpay)
      setDisBtns(true)
    } else if (
      !cloud &&
      !prodamus &&
      !tinkoff_sbp &&
      !paytabs &&
      !terpay &&
      moneta &&
      !tinkoff
    ) {
      setPayment_method_id(moneta)
      setDisBtns(true)
    } else if (
      !cloud &&
      !prodamus &&
      !tinkoff_sbp &&
      !paytabs &&
      !terpay &&
      !moneta &&
      tinkoff
    ) {
      setPayment_method_id(tinkoff)
      setDisBtns(true)
    }
  }, [cloud, prodamus, tinkoff_sbp, paytabs, terpay, moneta, tinkoff])

  useEffect(() => {
    //определяет как будет открываться страница оплаты iframe/не iframe/редирект
    if (
      payment_method_id === tinkoff_sbp ||
      payment_method_id === paytabs ||
      payment_method_id === terpay
    ) {
      //редирект
      setRedirect(true)
      setNoIframe(false)
    } else if (
      payment_method_id === prodamus ||
      payment_method_id === cloud ||
      payment_method_id === tinkoff
    ) {
      //не iframe для prodamus/cloud/tinkoff
      setNoIframe(true)
      setRedirect(false)
    } else {
      //iframe для moneta
      setRedirect(false)
      setNoIframe(false)
    }
  }, [
    payment_method_id,
    tinkoff_sbp,
    paytabs,
    terpay,
    prodamus,
    cloud,
    tinkoff
  ])

  useEffect(() => {
    //если в параметрах передали pm_id
    if (payMethodId) {
      setPayment_method_id(+payMethodId)
    }
  }, [payMethodId])

  useEffect(() => {
    //РФ или Иностранная при загрузке страницы
    if (payment_method_id) {
      if (
        payment_method_id === paytabs ||
        payment_method_id === prodamus ||
        urlOrgin === process.env.REACT_APP_PROD_AE_URL ||
        urlOrgin === process.env.REACT_APP_TEST_AE_URL
      ) {
        setRf(false)
        setEng(true)
      } else if (
        payment_method_id === cloud ||
        payment_method_id === tinkoff_sbp ||
        payment_method_id === tinkoff ||
        urlOrgin === process.env.REACT_APP_PROD_RU_URL ||
        urlOrgin === process.env.REACT_APP_TEST_RU_URL
      ) {
        setRf(true)
        setEng(false)
      }
    }
  }, [
    payment_method_id,
    paytabs,
    prodamus,
    cloud,
    tinkoff_sbp,
    urlOrgin,
    tinkoff
  ])

  useEffect(() => {
    //редирект для paytabs
    if (paytabs) {
      if (
        payment_method_id === paytabs ||
        +payMethodId === paytabs ||
        gate === '1'
      ) {
        if (urlOrgin === process.env.REACT_APP_PROD_RU_URL) {
          const newUrl = url.replace(
            process.env.REACT_APP_PROD_RU_URL,
            process.env.REACT_APP_PROD_AE_URL
          )
          window.location.href = newUrl
        } else if (urlOrgin === process.env.REACT_APP_TEST_RU_URL) {
          const newUrl = url.replace(
            process.env.REACT_APP_TEST_RU_URL,
            process.env.REACT_APP_TEST_AE_URL
          )
          window.location.href = newUrl
        }
      }
    }
  }, [paytabs, payMethodId, gate, urlOrgin, url, payment_method_id])

  function typeChange() {
    setRf(!rf)
    setEng(!eng)

    if (rf) {
      setPayment_method_id(prodamus || paytabs)

      if (paytabs) {
        const urlWithPmid = payMethodId
          ? url.replace(/pm_id=\d+/, `pm_id=${paytabs}`)
          : location.search
            ? `${url}&pm_id=${paytabs}`
            : `${url}?pm_id=${paytabs}`
        if (urlOrgin === process.env.REACT_APP_PROD_RU_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_PROD_RU_URL,
            process.env.REACT_APP_PROD_AE_URL
          )
          window.location.href = newUrl
        } else if (urlOrgin === process.env.REACT_APP_TEST_RU_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_TEST_RU_URL,
            process.env.REACT_APP_TEST_AE_URL
          )
          window.location.href = newUrl
        }
      }
    } else {
      setPayment_method_id(cloud || tinkoff)

      if (paytabs) {
        const urlWithPmid = payMethodId
          ? url.replace(/pm_id=\d+/, `pm_id=${cloud || tinkoff}`)
          : location.search
            ? `${url}&pm_id=${cloud || tinkoff}`
            : `${url}?pm_id=${cloud || tinkoff}`
        if (urlOrgin === process.env.REACT_APP_PROD_AE_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_PROD_AE_URL,
            process.env.REACT_APP_PROD_RU_URL
          )
          window.location.href = newUrl
        } else if (urlOrgin === process.env.REACT_APP_TEST_AE_URL) {
          const newUrl = urlWithPmid.replace(
            process.env.REACT_APP_TEST_AE_URL,
            process.env.REACT_APP_TEST_RU_URL
          )
          window.location.href = newUrl
        }
      }
    }
  }

  function payMethodChange(e) {
    setPayment_method_id(+e.target.value)
  }

  return (
    !recStatus &&
    !disBtns &&
    !payMethodId && (
      <>
        <div className={styles.payMethod}>
          {!!payment_method_id && <p>Выберите способ оплаты</p>}
        </div>
        {(!!prodamus || !!paytabs) && (
          <div className={styles.customRadio}>
            <input
              type="radio"
              name="payMethod"
              id="rf"
              disabled={
                gate === '1' || promoRes?.reserve_id || promoRes?.success
              }
              value={rf}
              checked={rf}
              onChange={typeChange}
            />
            <label className={styles.payLabel} htmlFor="rf">
              Российская
            </label>
            {gate !== '2' && (
              <>
                <input
                  type="radio"
                  name="payMethod"
                  id="inter"
                  checked={eng || gate === '1'}
                  value={eng}
                  disabled={
                    (!prodamus && !paytabs) ||
                    promoRes?.reserve_id ||
                    promoRes?.success
                  }
                  onChange={typeChange}
                />
                <label className={styles.payLabel} htmlFor="inter">
                  Иностранная
                </label>
              </>
            )}
          </div>
        )}
        {rf && gate !== '1' && (
          <div className={styles.rusCards}>
            {(!!cloud || !!tinkoff) && (
              <div className={styles.form_radio_btn}>
                <input
                  id="radio-1"
                  type="radio"
                  name="radio"
                  value={cloud || tinkoff}
                  onChange={(e) => payMethodChange(e)}
                  disabled={promoRes?.reserve_id}
                  checked={
                    cloud === payment_method_id || tinkoff === payment_method_id
                  }
                  required
                />
                <label htmlFor="radio-1">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIcon}
                    alt=""
                  />
                  <img src="/mir.png" className={styles.mirIcon} alt="" />
                </label>
              </div>
            )}

            {!!tinkoff_sbp && companyId !== 11 && (
              <div className={styles.form_radio_btn}>
                <input
                  id="radio-2"
                  type="radio"
                  name="radio"
                  value={tinkoff_sbp}
                  disabled={promoRes?.reserve_id}
                  onChange={(e) => payMethodChange(e)}
                  required
                />
                <label htmlFor="radio-2">
                  <img src="/sbp.png" alt="" style={{ width: '66px' }} />
                </label>
              </div>
            )}
            {!!terpay && (
              <div className={styles.form_radio_btn}>
                <input
                  id="radio-3"
                  type="radio"
                  name="radio"
                  value={terpay}
                  onChange={(e) => payMethodChange(e)}
                  disabled={promoRes?.reserve_id}
                  checked={terpay === payment_method_id}
                  required
                />
                <label htmlFor="radio-3">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIcon}
                    alt=""
                  />
                  <img src="/mir.png" className={styles.mirIcon} alt="" />
                </label>
              </div>
            )}
            {!!moneta && (
              <div className={styles.form_radio_btn}>
                <input
                  id="radio-3"
                  type="radio"
                  name="radio"
                  value={moneta}
                  onChange={(e) => payMethodChange(e)}
                  disabled={promoRes?.reserve_id}
                  checked={moneta === payment_method_id}
                  required
                />
                <label htmlFor="radio-3">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIcon}
                    alt=""
                  />
                  <img src="/mir.png" className={styles.mirIcon} alt="" />
                </label>
              </div>
            )}
          </div>
        )}

        {eng && (
          <div className={styles.engCards}>
            {!!prodamus && (
              <div className={styles.form_radio_btn}>
                <input
                  id="radio-2"
                  type="radio"
                  name="radio"
                  value={prodamus}
                  disabled={promoRes?.reserve_id}
                  checked={prodamus === payment_method_id}
                  onChange={(e) => payMethodChange(e)}
                  required
                />
                <label htmlFor="radio-2">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIconEn}
                    alt=""
                  />
                </label>
              </div>
            )}
            {!!paytabs && (
              <div className={styles.form_radio_btn}>
                <input
                  id="radio-2"
                  type="radio"
                  name="radio"
                  value={paytabs}
                  disabled={promoRes?.reserve_id}
                  checked={paytabs === payment_method_id}
                  onChange={(e) => payMethodChange(e)}
                  required
                />
                <label htmlFor="radio-2">
                  <img
                    src="/visaMaster.png"
                    className={styles.visaMasterIconEn}
                    alt=""
                  />
                </label>
              </div>
            )}
          </div>
        )}
      </>
    )
  )
}

export default PayMethods
