import SuccessPayForCart from './components/Thanks/SuccessPayForCart'
import CardSuccess from './components/Thanks/CardSuccess'
import PayForm from './components/PayForm/PayForm'
import Success from './components/Thanks/Success'
import { Route, Routes } from 'react-router-dom'
import Fail from './components/Thanks/Fail'
import { useEffect, useState } from 'react'
import Cart from './components/Cart/Cart'
import * as Sentry from '@sentry/react'

function App() {
  const [modalActive, setModalActive] = useState(true)
  const [payment, setPayment] = useState(null)
  const [pActive, setPActive] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [promocode, setPromocode] = useState('')
  const [promoRes, setPromoRes] = useState({})
  const [promocode_id, setPromocode_id] = useState(0)
  const [errorCode, setErrorCode] = useState(0)
  const [noIframe, setNoIframe] = useState(false)

  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  useEffect(() => {
    if (isIpOrAck) {
      document.title = 'Payment'
    }
  }, [isIpOrAck])

  useEffect(() => {
    if (pActive && payment) {
      if (!redirect && !noIframe) {
        const iframe = document.createElement('iframe')
        iframe.setAttribute('id', 'iframe')
        iframe.style.width = '100vw'
        iframe.style.height = '100vh'
        const html = payment
        iframe.src = `data:text/html;charset=utf-8,${encodeURIComponent(html)}`
        document.body.appendChild(iframe)
      } else if (noIframe) {
        const match = payment.match('<body>((.|\n)*)</body>')
        if (match) {
          const div = document.createElement('div')
          div.innerHTML = match[1]
          document.body.appendChild(div)
          const scripts = div.getElementsByTagName('script')
          if (scripts) {
            for (let script of scripts) {
              try {
                eval(script.innerText)
              } catch (e) {
                console.log('ERROR EXECUTE', script.innerText, e)
              }
            }
          } else {
            console.log('No scripts on page')
          }
        } else {
          console.log('Error get body from html')
          console.log(payment)
        }
      } else {
        window.open(payment, '_self')
      }
    }
  }, [pActive, payment, redirect, noIframe])

  return (
    <>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <Routes>
          <Route
            path="/:id"
            element={
              <PayForm
                active={modalActive}
                setActive={setModalActive}
                setPayment={setPayment}
                pActive={pActive}
                setPActive={setPActive}
                setRedirect={setRedirect}
                setNoIframe={setNoIframe}
                promoRes={promoRes}
                setPromoRes={setPromoRes}
                promocode={promocode}
                setPromocode={setPromocode}
                promocode_id={promocode_id}
                setPromocode_id={setPromocode_id}
                setErrorCode={setErrorCode}
              />
            }
          />
          <Route
            path="cart/:id"
            element={
              <Cart
                promoRes={promoRes}
                setPromoRes={setPromoRes}
                promocode={promocode}
                setPromocode={setPromocode}
                setPromocode_id={setPromocode_id}
              />
            }
          />
          <Route path="success" element={<Success />} />
          <Route path="success/cart" element={<SuccessPayForCart />} />
          <Route path="card" element={<CardSuccess />} />
          <Route path="fail" element={<Fail errorCode={errorCode} />} />
        </Routes>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default App
