import { declOfNum } from '../../utils/declOfNum'
import { BASE_URL } from './frame'
import axios from 'axios'

export const createOrder = async (orderDetails) => {
  const {
    name,
    phone,
    email,
    productsForDesc,
    company_id,
    frame_id,
    price,
    currency_id,
    payment_method_id,
    user_id,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    query,
    url,
    checkedProducts,
    prodForPay,
    promocode_id,
    ukey,
    balance_reserve_id,
    balance_summ,
    card_save_status,
    token,
    bindingProduct,
    productsParams,
    productsSign
  } = orderDetails

  try {
    let requestUrl = `${BASE_URL}/orders/`

    if (checkedProducts) {
      requestUrl += `?${checkedProducts}`
    }

    if (productsParams) {
      requestUrl += `${checkedProducts ? '&' : '?'}products=${productsParams}`
    }

    if (productsSign) {
      requestUrl += `${checkedProducts || productsParams ? '&' : '?'}products_sign=${productsSign}`
    }

    const HEADERS = {
      headers: {
        Accept: 'application/json',
        token
      }
    }

    const description = !bindingProduct
      ? productsForDesc === 1
        ? `Оплата: ${prodForPay} `
        : `Оплата: ${productsForDesc} ${declOfNum(productsForDesc, [
            'продукт',
            'продукта',
            'продуктов'
          ])}`
      : 'Привязка карты'

    const payload = {
      frame_id,
      payment_method_id,
      company_id,
      description,
      price,
      currency_id,
      name,
      phone,
      email,
      user_id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      data: query,
      url,
      promocode_id,
      balance_ukey: ukey,
      balance_reserve_id: balance_reserve_id || null,
      balance_summ,
      card_save_status
    }

    const res = await axios.post(requestUrl, payload, token ? HEADERS : null)
    return res
  } catch (e) {
    return e
  }
}
