import { BASE_URL } from './frame'
import axios from 'axios'

const reqUrl = process.env.REACT_APP_PF_AUTH
const fsReqUrl = process.env.REACT_APP_FS_AUTH

const token = localStorage.getItem('token')

const HEADERS = {
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    Token: token
  }
}

const getUrl = (base, path, params = '') => `${base}${path}${params}`

const handleRequest = async (method, url, data = null, headers = HEADERS) => {
  try {
    const res = await axios({ method, url, data, ...headers })
    return res
  } catch (e) {
    console.error(`Error during ${method.toUpperCase()} request to ${url}:`, e)
    throw e
  }
}

export const getUser = async (ukey, balance_status, finsovetnik) => {
  const baseUrl = finsovetnik ? fsReqUrl : reqUrl
  const fields = balance_status ? 'bill,balance' : 'bill'
  const params = ukey ? `&ukey=${ukey}` : ''
  const url = getUrl(baseUrl, `/fast_session?fields=${fields}`, params)
  return handleRequest('get', url)
}

export const getToken = async (finsovetnik) => {
  const baseUrl = finsovetnik ? fsReqUrl : reqUrl
  const url = getUrl(baseUrl, '/session?type=web&_lang=ru')
  return handleRequest('get', url)
}

export const login = async (credentials, finsovetnik) => {
  const baseUrl = finsovetnik ? fsReqUrl : reqUrl
  const url = getUrl(baseUrl, '/login')
  return handleRequest('post', url, credentials)
}

export const register = async (credentials, finsovetnik) => {
  const baseUrl = finsovetnik ? fsReqUrl : reqUrl
  const url = getUrl(baseUrl, '/register')
  return handleRequest('post', url, credentials)
}

export const recover = async (credentials, finsovetnik) => {
  const baseUrl = finsovetnik ? fsReqUrl : reqUrl
  const url = getUrl(baseUrl, '/recover')
  return handleRequest('post', url, credentials)
}

export const changePassword = async (credentials, finsovetnik) => {
  const baseUrl = finsovetnik ? fsReqUrl : reqUrl
  const url = getUrl(baseUrl, '/password')
  return handleRequest('post', url, credentials)
}

const requestUrl = `${BASE_URL}/balance/reserve/?`
export const getBalanceResult = async (
  ukey,
  balance,
  id,
  checkedProducts,
  payment_method_id
) => {
  const params = `ukey=${ukey}&summ=${balance}&frame_id=${id}&pm_id=${payment_method_id}`
  const url =
    checkedProducts === undefined
      ? `${requestUrl}${params}`
      : `${requestUrl}${params}&${checkedProducts}`
  return handleRequest('get', url)
}

export const deleteBalanceResult = async (reserve_id) => {
  const url = `${requestUrl}reserve_id=${reserve_id}`
  return handleRequest('delete', url)
}
