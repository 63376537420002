import RecurrentDescription from './components/RecurrentDescription/RecurrentDescription'
import ProductsAndPrice from './components/ProductsAndPrice/ProductsAndPrice'
import OfertaAndPrivacy from './components/OfertaAndPrivacy/OfertaAndPrivacy'
import { getCountSales, getPromoResult } from '../../store/actions/promocode'
import FormDescription from './components/FormDescription/FormDescription'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getToken, getUser } from '../../store/actions/user&balance'
import PromoBalance from './components/PromoBalance/PromoBalance'
import FormDisabled from './components/FormDisabled/FormDisabled'
import { createOrder } from '../../store/actions/createOrder'
import FormButton from './components/FormButton/FormButton'
import PayMethods from './components/PayMethods/PayMethods'
import FormHeader from './components/FormHeader/FormHeader'
import React, { useEffect, useMemo, useState } from 'react'
import { getFrameById } from '../../store/actions/frame'
import LoginModal from '../Login/LoginModal/LoginModal'
import EmailInput from './components/Inputs/EmailInput'
import PhoneInput from './components/Inputs/PhoneInput'
import { useDispatch, useSelector } from 'react-redux'
import NameInput from './components/Inputs/NameInput'
import warning from '../../icons/attention.svg'
import styles from './PayForm.module.scss'
import queryString from 'query-string'

function PayForm({
  active,
  setActive,
  setPayment,
  pActive,
  setPActive,
  setRedirect,
  setNoIframe,
  promoRes,
  setPromoRes,
  promocode,
  setPromocode,
  promocode_id,
  setPromocode_id,
  setErrorCode
}) {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [inputsError, setInputsError] = useState({
    name: true,
    phone: true,
    email: true
  })
  const [payment_method_id, setPayment_method_id] = useState(0)
  const [cloud, setCloud] = useState(0)
  const [price, setPrice] = useState(0)
  const [user, setUser] = useState({})
  const [balance_reserve_id, setBalance_reserve_id] = useState('')
  const [balance_summ, setBalance_summ] = useState(0)
  const [promoCount, setPromoCount] = useState(false)
  const [promoEr, setPromoEr] = useState('')
  const [countSalesStatus, setCountSalesStatus] = useState(false)
  const [countSalesError, setCountSalesError] = useState('')
  const [countSalesLink, setCountSalesLink] = useState('')
  const [currency, setCurrency] = useState('')
  const [card_save_status, setCard_save_status] = useState(false)
  const [loginModalActive, setLoginModalActive] = useState(false)
  const [bindingProduct, setBindingProduct] = useState(false)
  const [userKey, setUserKey] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [pmidForPaytabs, setPmidForPaytabs] = useState(null)

  const navigate = useNavigate()

  const result = useSelector((state) => state.result)
  const dispatch = useDispatch()

  const { id } = useParams()
  const location = useLocation()
  const user_id = user?.user_id
  const query = queryString.parse(location.search)
  const utm_source = new URLSearchParams(location.search).get('utm_source')
  const utm_medium = new URLSearchParams(location.search).get('utm_medium')
  const utm_campaign = new URLSearchParams(location.search).get('utm_campaign')
  const utm_content = new URLSearchParams(location.search).get('utm_content')
  const utm_term = new URLSearchParams(location.search).get('utm_term')

  const ukey =
    new URLSearchParams(location.search).get('ukey') || user?.ukey || userKey
  const url = window.location.href
  const payMethodId =
    new URLSearchParams(location.search).get('pm_id') || pmidForPaytabs
  const urlOrgin = new URL(url).origin
  const productsParams = new URLSearchParams(location.search).get('products')
  const productsSign = new URLSearchParams(location.search).get('products_sign')
  const preOrderId = new URLSearchParams(location.search).get('pre_order_id')

  const finsovetnik =
    urlOrgin === process.env.REACT_APP_PROD_FA_URL ||
    urlOrgin === process.env.REACT_APP_TEST_FA_URL

  const checkedProducts = Array.isArray(query.product)
    ? query.product?.map((el) => `product=${el}`).join('&')
    : query.product
        ?.split()
        .map((el) => `product=${el}`)
        .join('&')

  useEffect(() => {
    dispatch(
      getFrameById(
        id,
        checkedProducts,
        payMethodId,
        productsParams,
        productsSign,
        preOrderId
      )
    )
  }, [
    id,
    checkedProducts,
    payMethodId,
    productsParams,
    productsSign,
    preOrderId
  ])

  const products = result?.frame_products
  const prodForPay = products?.find((el) => el)?.product_info?.name
  const disabled = result?.disabled || typeof result === 'string'
  const frame_id = result?.id
  const company_id = result?.frame_companies?.find((el) => el)?.company_id

  const oldPrice = useMemo(() => {
    return result?.frame_products?.reduce(
      (sum, el) =>
        el.price === null ? sum + el.product_info.price : sum + el.price,
      0
    )
  }, [result])

  const newPrice = useMemo(() => {
    return promoRes?.products?.reduce((sum, el) => sum + el.new_price, 0)
  }, [promoRes])

  const balance_status = result?.balance_status || null

  const count_sales_status = result?.count_sales_status

  const token = localStorage.getItem('token')

  const isIpOrAck = process.env.REACT_APP_IS_IP_OR_ACK === 'true'

  useEffect(() => {
    if (!isIpOrAck) {
      async function getFastSession() {
        const res = await getUser(ukey, balance_status, finsovetnik)
        setUser(res.data)
      }
      if ((!name && !email && !phone) || !loginModalActive) {
        getFastSession()
      }
    }
  }, [balance_status, loginModalActive, finsovetnik, isIpOrAck])

  useEffect(() => {
    async function getSession() {
      const res = await getToken(finsovetnik)
      localStorage.setItem('token', res.data.token)
      if (!res.data.authorized || !res.data.token) {
        setLoginModalActive(true)
      } else {
        setLoginModalActive(false)
      }
    }
    if (result?.auth_type === 'C' || result?.auth_type === 'A') {
      getSession()
    }
  }, [result.auth_type, finsovetnik])

  useEffect(() => {
    setPrice(oldPrice ?? 0)
    if (promoRes.success) {
      if (Number.isInteger(newPrice)) {
        setPrice(newPrice)
      } else {
        setPrice(+newPrice.toFixed(2))
      }
    }
    if (promoRes.max_count_uses) {
      setPromoCount(true)
    } else {
      setPromoCount(false)
    }
  }, [oldPrice, newPrice, promoRes])

  useEffect(() => {
    if (result?.bind_required) {
      setCard_save_status(true)
      setBindingProduct(true)
    }
  }, [result])

  async function handleClick(e) {
    e.preventDefault()
    setIsLoading(true)
    if (price) {
      const orderDetails = {
        name,
        phone,
        email,
        productsForDesc: products?.length,
        company_id,
        frame_id,
        price,
        currency_id: currency,
        payment_method_id,
        user_id,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
        query,
        url,
        checkedProducts,
        prodForPay,
        promocode_id,
        ukey,
        balance_reserve_id,
        balance_summ,
        card_save_status,
        token,
        bindingProduct,
        productsParams,
        productsSign
      }
      const res = await createOrder(orderDetails)
      if (res?.data) {
        setPayment(res?.data)
      } else {
        setErrorCode(res?.response?.status)
        navigate('/fail')
      }
      setActive(false)
      setEmail('')
      setPhone('')
      setName('')
      setPActive(!pActive)
      window.scrollTo(0, 0)
    }
  }

  async function handleCheckSalesCount(e) {
    e.preventDefault()
    const res = await getCountSales(email, frame_id)
    if (res.data.result.success === false) {
      setCountSalesStatus(true)
      setCountSalesError(res.data.result.error_text)
      setCountSalesLink(res.data.result.link)
    } else {
      handleClick(e)
    }
  }

  async function handleCheckPromo(e) {
    e.preventDefault()
    const res = await getPromoResult(
      promocode,
      id,
      checkedProducts,
      payment_method_id
    )
    if (res.data.result === 'Promocode count ended') {
      setPromoEr('Действие промокода закончилось')
    } else if (res.data.result === 'Promocode not found') {
      setPromoEr('Срок действия промокода закончился')
    } else if (count_sales_status) {
      handleCheckSalesCount(e)
    } else {
      handleClick(e)
    }
  }

  return (
    <div className={active ? `${styles.modal} ${styles.active}` : styles.modal}>
      <div
        className={
          active
            ? `${styles.modal__content} ${styles.active}`
            : styles.modal__content
        }
        onClick={(e) => e.stopPropagation()}
      >
        {disabled ? (
          <FormDisabled error={result} disabled={result?.disabled} />
        ) : (
          <>
            <FormHeader
              finsovetnik={finsovetnik}
              bindingProduct={bindingProduct}
            />
            <form
              onSubmit={(e) =>
                promoCount
                  ? handleCheckPromo(e)
                  : count_sales_status
                    ? handleCheckSalesCount(e)
                    : handleClick(e)
              }
            >
              <PayMethods
                result={result}
                payment_method_id={payment_method_id}
                setPayment_method_id={setPayment_method_id}
                payMethodId={payMethodId}
                setRedirect={setRedirect}
                setNoIframe={setNoIframe}
                cloud={cloud}
                setCloud={setCloud}
                promoRes={promoRes}
                setCurrency={setCurrency}
                setPmidForPaytabs={setPmidForPaytabs}
              />

              <FormDescription
                finsovetnik={finsovetnik}
                bindingProduct={bindingProduct}
                disEmail={user?.email}
                disFio={user?.fio}
                disPhone={user?.phone}
              />

              <NameInput
                name={name}
                setName={setName}
                disFio={user?.fio}
                setInputsError={setInputsError}
              />

              <EmailInput
                email={email}
                setEmail={setEmail}
                disEmail={user?.email}
                setInputsError={setInputsError}
              />

              <PhoneInput
                phone={phone}
                setPhone={setPhone}
                disPhone={user?.phone}
                setInputsError={setInputsError}
              />

              <PromoBalance
                result={result}
                userBalance={user?.balance || null}
                promoRes={promoRes}
                setPromoRes={setPromoRes}
                checkedProducts={checkedProducts}
                promocode={promocode}
                setPromocode={setPromocode}
                setPromocode_id={setPromocode_id}
                setPromoEr={setPromoEr}
                payment_method_id={payment_method_id}
                oldPrice={oldPrice}
                productAmount={products?.length}
                ukey={ukey}
                balance_reserve_id={balance_reserve_id}
                setBalance_reserve_id={setBalance_reserve_id}
                setBalance_summ={setBalance_summ}
                cloud={cloud}
              />

              <ProductsAndPrice
                price={price}
                countSalesStatus={countSalesStatus}
                bindingProduct={bindingProduct}
                products={products}
                promoRes={promoRes}
                currency={currency}
                oldPrice={oldPrice}
              />

              <RecurrentDescription
                result={result}
                countSalesStatus={countSalesStatus}
                currency={currency}
              />

              <OfertaAndPrivacy
                result={result}
                card_save_status={card_save_status}
                setCard_save_status={setCard_save_status}
                countSalesStatus={countSalesStatus}
              />

              {!countSalesStatus && promoEr && (
                <div className={styles.promoEr}>
                  <img src={warning} alt="" />
                  <span>{promoEr}</span>
                </div>
              )}

              {countSalesStatus && (
                <div className={styles.promoEr}>
                  <img src={warning} alt="" />
                  <span>{countSalesError}</span>
                </div>
              )}

              <FormButton
                countSalesStatus={countSalesStatus}
                countSalesLink={countSalesLink}
                inputsError={inputsError}
                price={price}
                promoEr={promoEr}
                bindingProduct={bindingProduct}
                isLoading={isLoading}
              />
            </form>
          </>
        )}
      </div>
      {loginModalActive && (
        <LoginModal
          active={loginModalActive}
          setLoginModalActive={setLoginModalActive}
          setUserKey={setUserKey}
        />
      )}
    </div>
  )
}

export default PayForm
